import React, { Fragment } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Section from "elements/Section"
import Message from "elements/Message"
import FormInput from "elements/Form/FormInput"
import FormSelect from "elements/Form/FormSelect"
import ActionButtons from "elements/ActionButtons"
import RemoverConfirmation from "./RemoverConfirmation"

import { isBrowser } from "services/general"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { fetchByMigrationTag } from "./services/fetchByMigrationTag"

const ExtractorForm = ({ config, state, dispatch, loading, setLoading }) => {
  let options = Object.keys(config).map((key) => {
    return { value: key, label: key }
  })

  let enrollment = {}
  if (isBrowser())
    enrollment = JSON.parse(sessionStorage?.getItem("enrollment"))

  options = options?.filter((option) =>
    enrollment?.allowedDataSets?.includes(option?.value)
  )

  if (
    isBrowser() &&
    !enrollment?.allowedPaths?.includes(window?.location?.pathname)
  )
    navigate("/")

  const handleSubmitRemover = () => {
    setLoading(true)
    fetchByMigrationTag({
      config,
      type: state?.type?.value,
      migrationTag: state?.migrationTag,
      callback: ({ filteredDocuments }) => {
        setLoading(false)
        dispatch({
          type: "SHOW_MODAL",
          payload: {
            heading: "Confirm Deletion",
            isCard: true,
            headerClass: `has-text-danger has-background-danger-light has-text-weight-bold is-size-5`,
            content: (
              <RemoverConfirmation
                state={state}
                config={config}
                loading={loading}
                dispatch={dispatch}
                setLoading={setLoading}
                filteredDocuments={filteredDocuments}
              />
            ),
          },
        })
      },
    })
  }

  return (
    <Fragment>
      <Message color="primary">
        <div className="content">
          <p className="is-size-6">
            In case something goes wrong, please fill out and submit an issue
            form to:{" "}
            <a
              className="has-text-weight-bold"
              target="__blank"
              href="https://mdgr.cr/IT"
            >
              mdgr.cr/IT
            </a>
          </p>
        </div>
      </Message>
      <Formik
        onSubmit={handleSubmitRemover}
        initialValues={{
          type: state?.type,
          templateHeaderKey: { key: "", value: "" },
          option: "Migration Tag",
        }}
      >
        {() => (
          <Form>
            <Section
              title="Select Collection"
              subtitle="What kind of data you are deleting?"
              addOns={{ left: 1 }}
            >
              <FormSelect
                name={"type"}
                isRequired
                onChange={(value) => {
                  dispatch({ type: "SAVE_TYPE", payload: value })
                  dispatch({ type: "SAVE_HEADERS", payload: [] })
                }}
                options={options}
                value={state?.type}
              />
            </Section>
            <Section
              title="Migration Tag"
              subtitle="What is the migration tag of the data you want to remove?"
              addOns={{ left: 2 }}
            >
              <Message color="primary">
                <p className="is-size-6 has-text-weight-bold">
                  MIGRATION-220801-CLIENT
                </p>
                <p className="is-size-6">
                  Migration tags consists of date of migration and client code.
                  If you are not sure what is your client code, please contact
                  IT or submit a form at{" "}
                  <a
                    className="has-text-weight-bold"
                    target="__blank"
                    href="https://mdgr.cr/IT"
                  >
                    mdgr.cr/IT
                  </a>
                </p>
              </Message>
              <FormInput
                name={"migrationTag"}
                placeholder={"MIGRATION-220801-CLIENT"}
                isRequired
                maxLength={150}
                onChange={(event) => {
                  dispatch({
                    type: "SAVE_MIGRATION_TAG",
                    payload: event.target.value,
                  })
                }}
              />
            </Section>
            <ActionButtons
              back={{ label: "Back", link: "/" }}
              submit={{
                loading,
                callback: () => {},
                label: (
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} />
                    <span className="ml-1">Verify Data</span>
                  </span>
                ),
              }}
            />
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default ExtractorForm
