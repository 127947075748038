import firebase from "firebase"

export const fetchByMigrationTag = async ({
  type,
  config,
  callback,
  migrationTag,
}) => {
  let collection = config[type].collections[config[type].mainCollection]
  let filteredDocuments = []

  let documents = await firebase
    .firestore()
    .collection(collection?.Name)
    .where("migrationTag", "array-contains", migrationTag)
    .get()

  documents.forEach((document) => {
    filteredDocuments.push(document?.id)
  })

  if (callback) callback({ filteredDocuments })
}
