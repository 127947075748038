import React from "react"

import ActionButtons from "../Elements/ActionButtons"

import { removeData } from "./services/removeData"

const RemoverConfirmation = ({
  state,
  config,
  loading,
  dispatch,
  setLoading,
  filteredDocuments,
}) => {
  let filteredDocumentsLength = filteredDocuments?.length
  let actionButtonProps = {
    back: {
      label: "Back",
      callback: () => {
        dispatch({
          type: "HIDE_MODAL",
        })
      },
    },
  }

  if (filteredDocuments?.length)
    actionButtonProps.submit = {
      loading,
      callback: () => {
        setLoading(true)
        removeData({
          config,
          filteredDocuments,
          type: state?.type?.value,
          callback: () => {
            setLoading(false)
            dispatch({
              type: "HIDE_MODAL",
            })
            dispatch({
              type: "SHOW_MODAL",
              payload: {
                heading: "Successfully deleted data!",
                isCard: true,
                headerClass: `has-text-primary has-background-primary-light has-text-weight-bold is-size-5`,
                content: (
                  <div className="is-size-6">
                    <div className="mb-2">
                      Successfully deleted{" "}
                      <span className="has-text-weight-bold">
                        {(filteredDocumentsLength || 0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </span>{" "}
                      document
                      {`${filteredDocumentsLength > 1 ? "s" : ""}`}.
                    </div>

                    <ActionButtons
                      back={{
                        label: "Back",
                        callback: () => {
                          dispatch({
                            type: "HIDE_MODAL",
                          })
                        },
                      }}
                    />
                  </div>
                ),
              },
            })
          },
        })
      },
      color: "danger",
      label: "Delete Data",
    }

  return (
    <div className="has-text-left is-size-6">
      <p>
        Found{" "}
        <span className="has-text-weight-bold">
          {(filteredDocumentsLength || 0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </span>{" "}
        document
        {`${filteredDocumentsLength > 1 ? "s" : ""}`} with migration tag{" "}
        <span className="has-text-weight-bold">{state?.migrationTag}</span>.
      </p>
      {filteredDocumentsLength > 0 ? (
        <p className="mb-2">
          Are you sure you want to delete these documents?{" "}
          <span className="has-text-danger has-text-weight-bold">
            This action is irreversible!
          </span>
        </p>
      ) : (
        <p className="mb-2">
          Make sure you have inputted the right collection and migration tag.
        </p>
      )}
      <ActionButtons {...actionButtonProps} />
    </div>
  )
}

export default RemoverConfirmation
