import React, { useState } from "react"
import Layout from "layout"
import Remover from "../components/Remover"

const RemoverPage = () => {
  const [progress, setProgress] = useState(0)

  return (
    <Layout
      seoTitle="Remover"
      title="MG Kit Cloud Remover"
      progress={progress}
      display={{ helpCenterBanner: false, footer: false }}
      subtitle="Filter & search to remove data from the cloud"
    >
      <Remover isPrivate setProgress={setProgress} />
    </Layout>
  )
}

export default RemoverPage
