import React, { useContext, useState } from "react"

import Container from "layout/Container"
import PrivateRoute from "../PrivateRoute"
import { AppContext } from "../../context/AppContext"
import { useConfig } from "../../context/hooks/useConfig"
import RemoverForm from "./RemoverForm"
// import RemoverVerify from "./RemoverVerify"

const Remover = ({ isPrivate, setProgress }) => {
  const [loading, setLoading] = useState(false)
  const [view, setView] = useState("FORM")
  const { state, dispatch } = useContext(AppContext)

  let config = useConfig()
  const getView = () => {
    switch (view) {
      // case "VERIFY":
      //   return (
      //     <RemoverVerify
      //       state={state}
      //       dispatch={dispatch}
      //       setView={setView}
      //       config={config}
      //       loading={loading}
      //       setLoading={setLoading}
      //       setProgress={setProgress}
      //     />
      //   )
      case "FORM":
      default:
        return (
          <RemoverForm
            config={config}
            state={state}
            dispatch={dispatch}
            setView={setView}
            setLoading={setLoading}
            loading={loading}
          />
        )
    }
  }

  return (
    <PrivateRoute isPrivate={isPrivate}>
      <Container isCentered desktop={8} fullhd={7}>
        <div className="mb-3">{getView()}</div>
      </Container>
    </PrivateRoute>
  )
}

export default Remover
