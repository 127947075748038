import firebase from "firebase"
import lodash from "lodash"

export const removeData = async ({
  type,
  config,
  callback,
  filteredDocuments,
}) => {
  let collection = config[type].collections[config[type].mainCollection]

  let documentReferences = []
  for (const document of filteredDocuments) {
    let reference = await firebase
      .firestore()
      .collection(collection?.Name)
      .doc(document)

    documentReferences.push(reference)
  }

  let batches = lodash.chunk(documentReferences, 500).map((chunk) => {
    let firestoreBatch = firebase.firestore().batch()
    chunk.forEach((reference) => firestoreBatch.delete(reference))
    return firestoreBatch.commit()
  })

  await Promise.all(batches)
  if (callback) callback({ filteredDocuments })
}
