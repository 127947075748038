import { useStaticQuery, graphql } from "gatsby"

const ALL_CONFIG_QUERY = graphql`
  query MyQuery {
    allAirtableDataSets(sort: { fields: data___Order, order: ASC }) {
      edges {
        node {
          data {
            name: Name
            action: Action
            order: Order
            extractionDate: Extraction_Date {
              data {
                firestoreKey: Firestore_Key
                templateHeader: Template_Header
              }
            }
            userKey: User_Key {
              data {
                firestoreKey: Firestore_Key
              }
            }
            mainCollection: Main_Collection {
              data {
                name: Name
              }
            }
            collections: Collections {
              data {
                name: Name
                path: Path
                columns: Columns {
                  data {
                    templateHeader: Template_Header
                    firestoreKey: Firestore_Key
                    dataType: Data_Type
                    source: Source
                    mapValues: Map_Values {
                      data {
                        templateHeader: Template_Header
                        dataType: Data_Type
                      }
                    }
                  }
                }
                Key {
                  data {
                    firestoreKey: Firestore_Key
                  }
                }
                BU
              }
            }
          }
        }
      }
    }
  }
`

export const useConfig = () => {
  let data = useStaticQuery(ALL_CONFIG_QUERY)
  let config = {}
  data.allAirtableDataSets.edges.forEach((item, index) => {
    config[item.node.data.name] = {
      requiredColumns:
        item.node.data.requiredColumns?.map((col) => col.data.templateHeader) ||
        [],
      order: item.node.data.order,
      collections: {},
      mainCollection:
        item.node.data.mainCollection?.map((col) => col.data.name)[0] || "",
      userKey:
        (item.node.data?.userKey &&
          item.node.data?.userKey[0]?.data?.firestoreKey) ||
        "",
      firestoreKey: {},
      source: {},
      dataTypes: {},
      extractionDate: {},
      extractionTemplateKey: {},
      action: item.node.data.action,
    }

    if (item.node.data.collections) {
      item.node.data.collections.forEach((collection) => {
        config[item.node.data.name].collections[collection.data.name] = {
          Name: collection.data.name,
          bu: collection.data.BU,
          path: collection.data.path || "",
          data:
            collection.data.columns?.map(
              (column) => column.data.firestoreKey
            ) || [],
          subcollections: collection.data.Subcollections?.map((subcol) => {
            return {
              name: subcol.data.name,
              path: subcol.data.path,
            }
          }),
          key:
            collection.data.Key?.map((key) => key.data.firestoreKey)[0] || "",
        }
      })

      item.node.data.collections.forEach((collection) => {
        collection.data.columns.forEach((column) => {
          config[item.node.data.name].firestoreKey[column.data.templateHeader] =
            column.data.firestoreKey
          config[item.node.data.name].dataTypes[column.data.firestoreKey] =
            column.data.dataType
          config[item.node.data.name].source[column.data.firestoreKey] =
            column.data.source
        })
      })
    }
    if (item.node.data.extractionDate)
      item.node.data.extractionDate.forEach((field) => {
        config[item.node.data.name].extractionDate[field.data.templateHeader] =
          field.data.firestoreKey
      })
  })

  return config
}
